<template>
  <div v-custom-attr:id="`${translateKey}Wrapper`" v-custom-attr:data-test-id="elementTest">
    <CarouselContainer
      class="product-carousel"
      :title="labels?.title ?? $t(`home.${translateKey}.title`)"
      :count="count"
      :subtitle="labels?.subtitle ?? $t(`home.${translateKey}.message`)"
      :link-label="labels?.link ?? buttonText"
      :link-src="labels?.linkSrc ?? `/category/${link}`"
      :display-button="displayButton"
      :element-parent="`${translateKey}Wrapper`"
      :class="{ mobile: !isPc }"
      :data-test-id="`${elementTest}-carousel`"
      :test-id="elementTest"
    >
      <template v-if="featuredCell" #contentCarouselContainer>
        <FeaturedProductsCardItem
          v-for="(reference, i) in references"
          :key="reference.uid"
          :reference-info="reference"
          :fast-add-to-cart="fastAddToCart"
          :test-id="elementTest + '-' + i"
        />
      </template>
      <template v-else #contentCarouselContainer>
        <ReferenceCell
          v-for="(reference, i) in references"
          :key="reference.uid"
          v-lazycomponent="i > 3"
          class="u-position-relative"
          :reference="reference"
          :element-number="i"
          :horizontal-cell="isPc ? horizontalCell : false"
          :lazy-img="lazy"
          :fast-add-to-cart="fastAddToCart"
          :data-test-id="elementTest + '-reference-cell' + i"
          :test-id="elementTest"
        />
      </template>
    </CarouselContainer>
  </div>
</template>

<script setup>
import CarouselContainer from '@/components/Carousel/CarouselContainer'
import ReferenceCell from '@/components/Reference/ReferenceCell/ReferenceCell'
import FeaturedProductsCardItem from '@/components/Carousel/FeaturedProductsCarousel/FeaturedProductsCardItem'

const { isPc } = useDeviceSize()
const props = defineProps({
  references: { type: Array, default: () => [] },
  count: { type: Number, default: null },
  horizontalCell: { type: Boolean, default: false },
  featuredCell: { type: Boolean, default: false },
  link: { type: String, default: '' },
  translateKey: { type: String, default: '' },
  lazy: { type: Boolean, default: true },
  fastAddToCart: { type: Boolean, default: false },
  displayButton: { type: Boolean, default: true },
  labels: { type: Object, default: () => null },
  elementTest: { type: String, default: '' },
})

provide('lazy', props.lazy)

const buttonText = computed(() => {
  const { $i18n } = useNuxtApp()
  return $i18n.te(`home.${props.translateKey}.button`) ? $i18n.t(`home.${props.translateKey}.button`) : null
})
</script>

<style lang="scss">
.product-carousel {
  min-height: 215px;
}
</style>
